.error-boundary {
    background-color: black;
    color: white;
    min-height: 100vh;
    
    display: grid;
    place-content: baseline;
    padding: 24px;

    h1{
        margin: 0;
    }
}