*{
    box-sizing: border-box;
}
.no-text-decoration{
    text-decoration: none;

    &:focus &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
}

.center-text-only-screens{
    place-self: center;
    text-align: center;
}

body{
    background-color: #0C0120;
}
.center-text-only-screens{
    place-self: center;
    text-align: center;
}

body{
    background-color: #0C0120;
}