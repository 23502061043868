.home{
    position: relative;
    z-index: 0;
}

.gxs-gradient-background {
    background-image: radial-gradient(50% 51.02% at 50% 49.81%, #F76F87 0%, #771FFF 57.58%, #0C0120 100%);
    // background-position-x: -243px;
    background-repeat: repeat-x;
    // background-position-y: -693px;
    background-repeat: no-repeat;
    // background-size: 173%;
    height: 1300px;
    width: 1300px;
    position: absolute;
    // top: -24px;
    // left: -24px;
    left: -824px;
    top: -1075px;
    z-index: -1;
    opacity: 0.5;
}

.gxs-gradient-background--business {
    background: radial-gradient(52.76% 53.84% at 52.6% 46.07%, #0AFFB4 15%, #320D6B 48%, #0C0120 79.5%);
    background-repeat: no-repeat;
    height: 1300px;
    width: 1300px;
    position: absolute;
    left: -640px;
    top: -890px;
    z-index: -1;
    opacity: 0.5; 
}