.animate-input{
    animation: in-from-bottom 300ms forwards;
}

@keyframes in-from-bottom {
    0%{
        transform: translateY(100%);
    };
    100%{
        transform: translateY(0%);
    }
}