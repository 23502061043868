// @use "../../index" as variables;

// #report-fraud {
//     text-align: left;

//     #header {
//         color: variables.$text-primary-pearl-500;
//         font-weight: 600;
//         font-size: 20px;
//     }

//     #content {
//         color: variables.$text-primary-pearl-500;
//         font-weight: 400;
//         font-size: 14px;
//     }
// }
.no-select {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
}

.fixed-bottom {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  text-align: left;
  color: #FFFFFF;
  padding: 24px;
}

.fixed-bottom a {
  color: #896FBD;
  text-decoration: none;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.fixed-bottom a:active {
    text-decoration: none;
}