.dir-cards{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height:100%;
    .imgHolder{
        display: flex;
        width: 32px;
        height: 32px;
    }
    .description{
        align-self: flex-start;
        margin: 0;
        text-align: initial;
    }
}
