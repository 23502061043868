@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');

html {
    scroll-padding-top: var(--scroll-padding-top, 60px);
}

body{
    margin: 0;
    box-sizing: border-box;
    /* max-width: 400px; */
    margin: auto;
    /* background-color: radial-gradient(50% 51.02% at 50% 49.81%, #F76F87 0%, #771FFF 57.58%, #0C0120 100%); */
    /* background-position: ; */
}